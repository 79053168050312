import { Component, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  @ViewChild("formwrapper", { static: false }) formwrapper;

  inputFields = {
    name: {
      value: "",
      name: "name",
      onFocus: false,
      labelabove: false,
    },
    email: {
      value: "",
      name: "email",
      onFocus: false,
      labelabove: false,
    },
    message: {
      value: "",
      name: "message",
      onFocus: false,
      labelabove: false,
    },
  };

  constructor() {}

  ngOnInit() {}

  toggleFocus(event, focus) {
    const name = event.target.name;
    this.inputFields[name].onFocus = focus;

    if (focus || this.inputFields[name].value !== "") {
      this.toggleLabelAbove(true, name);
    } else {
      this.toggleLabelAbove(false, name);
    }
  }

  toggleLabelAbove(toggle, name) {
    this.inputFields[name].labelabove = toggle;
    console.log(this.inputFields[name]);
  }

  onSubmit() {
    console.log(`Submit clicked`);
    console.log(`Name: ${this.inputFields.name.value}`);
    console.log(`Email: ${this.inputFields.email.value}`);
    console.log(`Message: ${this.inputFields.message.value}`);
  }
}
