import { Component, OnInit, ViewChild, Renderer2 } from "@angular/core";
import { ShowQuoteandBrands } from "../../services/showquoteandbrands.service";
import { DevLockService } from '../../services/dev-lock.service';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  @ViewChild("clients", { static: false }) clients;
  count = 0;
  showQuote = false;
  showBrands = false;
  // @ViewChild("formwrapper", { static: false }) formwrapper;

  // inputFields = {
  //   name: {
  //     value: "",
  //     name: "name",
  //     onFocus: false,
  //     labelabove: false
  //   },
  //   email: {
  //     value: "",
  //     name: "email",
  //     onFocus: false,
  //     labelabove: false
  //   },
  //   message: {
  //     value: "",
  //     name: "message",
  //     onFocus: false,
  //     labelabove: false
  //   }
  // };

  isAutherized;

  constructor(
    private renderer: Renderer2,
    private showQuoteAndBrandsService: ShowQuoteandBrands,
    private devlock: DevLockService
  ) { }

  ngOnInit() {
    this.infiniteScroll = this.infiniteScroll.bind(this);
    setInterval(this.infiniteScroll, 2000);
    // this.showQuoteAndBrandsService.changeEmitted.subscribe((state) => {
    //   if (state === "show") {

    //   } else if (state === "hide") {

    //   }
    // });
    this.devlock.isAutherized.subscribe(isAuth => {
      this.isAutherized = isAuth;
    })
  }

  callShowQuoteAndBrands() {
    this.showQuote = true;
    this.showBrands = true;
  }

  callHideQuoteAndBrands() {
    this.showQuote = false;
    this.showBrands = false;
  }

  infiniteScroll() {
    if (this.showBrands) {
      if (this.count < 8) {
        this.buttonClick("right");
      } else {
        this.clients.nativeElement.scrollLeft = 0;
        this.count = 0;
        return null;
      }
      this.count++;
    }
  }

  buttonClick(type) {
    if (type === "left") {
      this.clients.nativeElement.scrollLeft -= 120;
    } else if (type === "right") {
      this.clients.nativeElement.scrollLeft += 120;
    }
  }

  // toggleFocus(event, focus) {
  //   const name = event.target.name;
  //   this.inputFields[name].onFocus = focus;

  //   if (focus || this.inputFields[name].value !== "") {
  //     this.toggleLabelAbove(true, name);
  //   } else {
  //     this.toggleLabelAbove(false, name);
  //   }
  // }

  // toggleLabelAbove(toggle, name) {
  //   this.inputFields[name].labelabove = toggle;
  //   console.log(this.inputFields[name]);
  // }

  // onSubmit() {
  //   console.log(`Submit clicked`);
  //   console.log(`Name: ${this.inputFields.name.value}`);
  //   console.log(`Email: ${this.inputFields.email.value}`);
  //   console.log(`Message: ${this.inputFields.message.value}`);
  // }
}
