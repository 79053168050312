import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DevLockService } from '../services/dev-lock.service';

@Injectable({
  providedIn: 'root'
})
export class DevGuardGuard implements CanActivate {
  constructor(private auth: DevLockService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return new Observable(observer => {
      this.auth.isAutherized.subscribe(autherized => {
        observer.next(autherized);
        if (!autherized) {
          this.router.navigateByUrl('/password');
        }
      })
    });
  }

}
