import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";
import { VideoService } from "src/app/services/video.service";
import Player from "@vimeo/player";
import { Observable } from "rxjs";

@Component({
  selector: "app-videos-page",
  templateUrl: "./videos-page.component.html",
  styleUrls: ["./videos-page.component.scss"],
})
export class VideosPageComponent implements OnInit {
  @ViewChild("popup", { static: false }) popup: ElementRef;
  @ViewChild("popupiframe", { static: false }) popupIframe: ElementRef;
  @ViewChild("backgroundiframe", { static: false })
  backgroundIframe: ElementRef;

  thumbnailsFolderRef;
  videoThumbnailUrls: {
    id: any;
    url: Observable<string | null>;
    name: string;
  }[] = [];

  popupPlayer;
  backgroundPlayer;
  showpopup = true;
  loaded = false;
  validSectionNames = [
    "digital",
    "animation",
    "corporate",
    "originals",
    "products",
    "photoshoot",
  ];

  showreelName = "";

  showreel = "";
  showreelPopup = "";
  vidIds = [];

  showCustom = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storage: AngularFireStorage,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    const section = this.route.snapshot.paramMap.get("section");
    if (this.validSectionNames.includes(section)) {
      const thumbnailsFolder = this.storage.ref(section);
      this.thumbnailsFolderRef = thumbnailsFolder;
      this.showreelName = section;
      this.loadVideos(section);
      this.showCustom = true;
    } else {
      this.router.navigateByUrl("/404notfound");
    }
  }

  private oniframeLoad(event) {
    const elementSrc = event.target.src;
    if (elementSrc !== "") {
      const player = new Player(event.target);
      this.backgroundPlayer = player;
      player.on("play", () => {
        this.loaded = true;
      });
    }
  }

  private onPopupLoad(event) {
    const elementSrc = event.target.src;
    if (elementSrc !== "") {
      const player = new Player(event.target);
      this.popupPlayer = player;
    }
  }

  private loadVideos(section) {
    // getData takes in document name and returns observable of that document
    // we are passing in sectionname which is the name of a document in firebase database
    const videos = [];
    this.videoService.getData(section).subscribe((section) => {
      section.videos.forEach((video, index) => {
        videos.push(video.url);
        console.log(videos);

        const id = this.videoService.getVideoId(video.url);
        // const image = this.storage
        //   .ref(`${this.showreelName}/${id}.png`)
        //   .getDownloadURL();
        this.videoThumbnailUrls.push({
          id: id,
          url: video.image,
          name: video.name,
        });
      });
    });
    this.videoService.getData("showreels").subscribe((showreels) => {
      const video = showreels.videos.filter((video, index) => {
        console.log(video);
        return this.validSectionNames.indexOf(section) === index - 1;
      })[0];
      const playableShowreel = this.videoService.convertToBackgroundPlayerLink(
        video
      );
      const playableShowreelPopup = this.videoService.convertToPlayerLink(
        video
      );
      console.log(`playableShowreelPopup ${playableShowreelPopup}`);
      this.showreel = playableShowreel;
      this.showreelPopup = playableShowreelPopup;
      console.log(`Showreel video: ${video}`);
      console.log(showreels);
    });
  }

  private showShowreel() {
    if (!this.popup.nativeElement.classList.contains("showpopup")) {
      this.popup.nativeElement.classList.add("showpopup");
      this.backgroundPlayer.pause();
    }
  }

  private hideShowreel() {
    if (this.popup.nativeElement.classList.contains("showpopup")) {
      this.popup.nativeElement.classList.remove("showpopup");
      this.popupPlayer.pause();
      this.backgroundPlayer.play();
    }
  }

  private onThumbnailClick(id) {
    console.log(`Clicked on ${id}`);
    this.router.navigateByUrl(`videos/${this.showreelName}/${id}`);
  }
}
