import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGaurd implements CanActivate {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private auth: AuthService
  ) {}

  canActivate(): Observable<boolean> {
    return new Observable((observer) => {
      this.auth.userIsAuthenticated().subscribe((isAuthenticated) => {
        observer.next(isAuthenticated);
        if (!isAuthenticated) {
          this.router.navigateByUrl("/login");
        }
      });
    });
  }
}
