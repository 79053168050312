import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { VideoService } from "../../services/video.service";

@Component({
  selector: "app-edit-video",
  templateUrl: "./edit-video.component.html",
  styleUrls: ["./edit-video.component.scss"],
})
export class EditVideoComponent implements OnInit {
  id = 0;
  section = "";
  validSectionNames = ["digital", "animation", "corporate"];
  video;

  editName = false;
  editLink = false;

  newVideo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    const section = this.route.snapshot.paramMap.get("section");
    const id = this.route.snapshot.paramMap.get("id");

    if (this.validSectionNames.includes(section)) {
      this.id = parseInt(id);
      this.section = section;
      this.loadVideoDetails(section, this.id);
    }
  }

  private loadVideoDetails(section, id) {
    this.videoService.getData(section).subscribe((section) => {
      console.log(section);
      console.log(id);
      if (section.videos.length !== 0) {
        const video = section.videos.filter((video) => video.id === id)[0];
        this.video = video;
        this.newVideo = { ...video };
      }
    });
  }

  private edit(type) {
    console.log("Clicked on edit");
    if (type === "name") {
      this.editName = true;
    }
  }

  private delete(type) {
    console.log("Clicked on delete");
    const section = this.route.snapshot.paramMap.get("section");
    if (type === "name") {
      if (
        confirm(
          `Are you sure you want to DELETE ${this.video.name} from the website? You will NOT be able to reverse this action `
        )
      ) {
        this.editName = false;
        this.videoService.deleteVideo(section, this.video);
        this.router.navigateByUrl("/admin");
      }
    }
  }

  private save(type) {
    const section = this.route.snapshot.paramMap.get("section");
    if (type === "name") {
      if (
        confirm(
          `Are you sure you want to update ${this.video.name} to ${this.newVideo.name}`
        )
      ) {
        this.editName = false;
        console.log(true);
        this.videoService.updateVideo(section, this.newVideo);
      }
    }
  }

  private cancel(type) {
    if (type === "name") {
      this.editName = false;
      this.newVideo = { ...this.video };
    }
  }

  private refreshThumbnail() {
    // const link = this.videoService
    //   .getVideoThumbnail(this.video.id)
    //   .subscribe((data: any) => {
    //     let image = data.pictures.sizes.filter((size) => size.width === 295)[0];
    //     console.log(image.link);
    //   });
    const section = this.route.snapshot.paramMap.get("section");
    this.videoService.refreshThumbnail(section, this.id);
  }
}
