import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { VideoService } from "../../services/video.service";
import { FunctiontestService } from '../../services/functiontest.service';

@Component({
  selector: "app-admin-page",
  templateUrl: "./admin-page.component.html",
  styleUrls: ["./admin-page.component.scss"],
})
export class AdminPageComponent implements OnInit {
  sections = ["digital", "animation", "corporate"];
  videos = {
    digital: [],
    animation: [],
    corporate: [],
  };
  digital: any[];
  animation: any[];
  corporate: any[];
  productFilms: any[];

  constructor(private videoService: VideoService, private functiontestService: FunctiontestService, private router: Router) { }

  ngOnInit() {
    this.loadVideos();
    this.testFunction();
    // this.videoService.setData();
  }

  loadVideos() {
    this.sections.forEach((section) => {
      this.videoService.getData(section).subscribe((data) => {
        this.videos[section] = data.videos;
        console.log(this.videos);
      });
    });
  }

  testFunction() {
    this.functiontestService.hello();
  }

  // getVideos() {
  //   console.log("clicked on get videos");
  //   this.videoService.getVideos();
  // }

  navigate(section, video) {
    this.router.navigateByUrl(`/admin/edit-video/${section}/${video.id}`);
  }
}
