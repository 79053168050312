import { Component, OnInit } from "@angular/core";
import { VideoService } from "../../services/video.service";
import { ShowQuoteandBrands } from "../../services/showquoteandbrands.service";
import { Router } from "@angular/router";
import Player from "@vimeo/player";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  iframeSrcArr: String[] = [];
  id: number = 0;
  loaded: boolean = false;
  numberOfIframesLoaded: number = 0;

  iframePlayers: any[] = [0, 1, 2, 3, 4, 5, 6];

  constructor(
    private videoService: VideoService,
    private router: Router,
    private showQuoteAndBrandsService: ShowQuoteandBrands
  ) { }

  ngOnInit() {
    console.log(`Initiating landing page...`);
    this.loadVideos();
    this.showQuoteAndBrandsService.emitChange("show");
  }

  ngOnDestroy() {
    this.showQuoteAndBrandsService.emitChange("hide");
  }

  private loadVideos() {
    // getData takes in document name and returns observable of that document
    // we are passing in showreels which is the name of a document, showreels document  contains all video links of showreels
    this.videoService.getData("showreels").subscribe((showreels) => {
      showreels.videos.forEach((video, index) => {
        // if showreels contain more than what we require, don't load them
        console.log(`Index: ${index}, Video: ${video}`);
        const bacgroundPlayerLink = this.videoService.convertToBackgroundPlayerLink(
          video
        );
        // console.log(video);
        this.iframeSrcArr.push(bacgroundPlayerLink);
      });
    });
  }

  // Gets called when mouse hovers any section
  // Changes glabal id element
  // Pauses previous video and
  // Plays current video
  onMouseEnter(event) {
    const elementId = event.target.id;
    if (elementId.includes("section")) {
      const elIdNum = parseInt(elementId.charAt(elementId.length - 1));
      // we don't want to play and pause the same video if user leaves and enters same section without hovering over any other section
      if (elIdNum !== this.id) {
        this.iframePlayers[this.id].pause().catch((error) => {
          // ignore errors if play event is interrupted by pause event or vice versa
          // this happens when user moves mouse to adjecent section and back to first section too quickly.
          // We don't care about this error, as we infact want it to interrupt the previous action being called and call the current action
          if (error.name !== "PlayInterrupted") {
            console.error(error);
          }
        });
        this.iframePlayers[elIdNum].play().catch((error) => {
          if (error.name !== "PlayInterrupted") {
            console.error(error);
          }
        });
        this.id = elIdNum;
      }
    }
  }

  onSectionClick(event) {
    const element = event.currentTarget;
    const elementId = element.id;

    if (elementId.includes("section")) {
      const elIdNum = parseInt(elementId.charAt(elementId.length - 1));

      switch (elIdNum) {
        case 1:
          this.router.navigateByUrl("/videos/digital");
          break;

        case 2:
          this.router.navigateByUrl("/videos/animation");
          break;

        case 3:
          this.router.navigateByUrl("videos/corporate");
          break;

        case 4:
          this.router.navigateByUrl("videos/originals");
          break;

        case 5:
          this.router.navigateByUrl("videos/products");
          break;

        case 6:
          this.router.navigateByUrl("videos/photoshoot");
          break;

        default:
          break;
      }
    }
  }

  // Everytime iframe loads without empty src attribute pause all videos except the 0th one, and increment numberOfIframesLoaded
  // When numberOfIframesLoaded reaches 7, set loaded to true
  // Loaded variable will hide the loading component and show the landing page.
  oniframeLoad(event) {
    const elementSrc = event.target.src;
    const elementId = event.target.id;
    if (elementSrc !== "") {
      const elIdNum = elementId.charAt(elementId.length - 1);
      this.numberOfIframesLoaded++;
      const player = new Player(event.target);
      if (elementId !== "iframe0") {
        player.pause();
      }
      this.iframePlayers[elIdNum] = player;
    }
    if (this.numberOfIframesLoaded === 7) {
      this.loaded = true;
    }
  }
}
