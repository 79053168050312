import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { DevLockService } from '../../services/dev-lock.service';

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit {
  @Output() navigateToEvent = new EventEmitter();
  transparent = true;
  myVar = "Hello World!";
  loggedIn;
  isAuthenticated;
  isAutherized;

  constructor(private window: Window, public auth: AuthService, private devlock: DevLockService) { }

  ngOnInit() {
    this.window.addEventListener("scroll", () => {
      if (this.window.scrollY < 300) {
        this.transparent = true;
      } else {
        this.transparent = false;
      }
    });
    this.auth.isLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
    this.auth.userIsAuthenticated().subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.devlock.isAutherized.subscribe(isAuth => {
      this.isAutherized = isAuth;
    })
  }
}
