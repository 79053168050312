import { Component, OnInit } from '@angular/core';
import { DevLockService } from '../../services/dev-lock.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  inputFields = {
    password: {
      value: '',
      name: 'name',
      onFocus: false,
      labelabove: false,
    }
  }

  isAutherized;

  constructor(private devlock: DevLockService) { }

  ngOnInit() {
    this.devlock.isAutherized.subscribe(isAuth => {
      this.isAutherized = isAuth;
    })
  }

  toggleFocus(event, focus) {
    const name = event.target.name;
    this.inputFields[name].onFocus = focus;

    if (focus || this.inputFields[name].value !== '') {
      this.toggleLabelAbove(true, name);
    } else {
      this.toggleLabelAbove(false, name);
    }
  }

  toggleLabelAbove(toggle, name) {
    this.inputFields[name].labelabove = toggle;
  }

  onSubmit() {
    this.devlock.login(this.inputFields.password.value);
  }

}
