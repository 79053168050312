import { Component, ViewChild, EventEmitter } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @ViewChild("footer", { static: false }) footer;
  title = "ddbwebsite";

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  onActivate(event: EventEmitter<any>) {
    if (event.constructor.name === "LandingPageComponent") {
      this.footer.callShowQuoteAndBrands();
    }
  }

  onDeactivate(event: EventEmitter<any>) {
    if (event.constructor.name === "LandingPageComponent") {
      this.footer.callHideQuoteAndBrands();
    }
  }
}
