import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DevLockService {

  public isAutherized = new BehaviorSubject(false);

  constructor(private http: HttpClient) { }

  async login(password) {
    const res = this.http.post('https://us-central1-ddbwebsite-f89ac.cloudfunctions.net/authorize', { password });
    res.subscribe((data: any) => {
      this.isAutherized.next(data.valid);
    })
  }

  logout() {
    this.isAutherized.next(false);
  }
}
