import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { auth } from 'firebase/app'
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(public afAuth: AngularFireAuth, private afs: AngularFirestore) { }

  login() {
    this.afAuth.signInWithPopup(new auth.GoogleAuthProvider());
  }
  logout() {
    this.afAuth.signOut();
  }

  isLoggedIn(): Observable<any> {
    return new Observable((observer) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  userIsAuthenticated(): Observable<boolean> {
    const userObservable = this.getAuthenticatedUserObservable();
    return new Observable((observer) => {
      userObservable.subscribe((user) => {
        if (user) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }

  getAuthenticatedUserObservable(): Observable<any> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  getUserObservable(): Observable<any> {
    return new Observable((observer) => {
      this.afAuth.authState.subscribe((user) => {
        console.log(user);
        observer.next(user);
      });
    });
  }
}
