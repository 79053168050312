import { Component, OnInit } from "@angular/core";
import { VideoService } from "src/app/services/video.service";

@Component({
  selector: "app-newvideo",
  templateUrl: "./newvideo.component.html",
  styleUrls: ["./newvideo.component.scss"],
})
export class NewvideoComponent implements OnInit {
  selectedSection = "";

  sections = ["digital", "animation", "corporate"];

  video = {
    id: 0,
    image: "",
    name: "",
    url: "",
  };

  constructor(private videoService: VideoService) {}

  ngOnInit() {}

  save() {
    if (
      this.video.name !== "" &&
      this.video.url !== "" &&
      this.selectedSection !== ""
    ) {
      this.video.id = this.videoService.getVideoId(this.video.url);
      console.log(this.video);
      this.videoService.addVideo(this.selectedSection, this.video);
      this.videoService
        .getVimeoVideoData(this.video.id)
        .subscribe((data: any) => {
          console.log("got image data");
          let image = data.pictures.sizes.filter(
            (size) => size.width === 295
          )[0];
          this.video.image = image.link;
        });
    }
  }

  sectionChange(event) {
    console.log(`in section change`);
    console.log(event);
    // const section = event.target.value;
    // console.log(section);
    // this.section = section;
  }
}
