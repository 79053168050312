import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { VideoService } from "src/app/services/video.service";
import Player from "@vimeo/player";

@Component({
  selector: "app-video-page",
  templateUrl: "./video-page.component.html",
  styleUrls: ["./video-page.component.scss"],
})
export class VideoPageComponent implements OnInit {
  @ViewChild("popup", { static: false }) popup: ElementRef;
  @ViewChild("popupiframe", { static: false }) popupIframe: ElementRef;

  section = "";
  id = 0;
  videoName = "";
  videos = [];
  validSectionNames = [
    "digital",
    "animation",
    "corporate",
    "youtube",
    "productshoot",
    "photoshoot",
  ];
  backgroundVideoSrc = "";
  backgroundPlayer;
  loaded = false;
  popupPlayer;
  popupSrc;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private videoService: VideoService
  ) {}

  ngOnInit() {
    const section = this.route.snapshot.paramMap.get("section");
    const id = this.route.snapshot.paramMap.get("id");

    if (this.validSectionNames.includes(section)) {
      this.id = parseInt(id);
      this.section = section;
      this.loadVideos(section, this.id);
    } else {
      this.router.navigateByUrl("/404notfound");
    }
  }

  private loadVideos(section, id) {
    // getData takes in document name and returns observable of that document
    // we are passing in the sectionname which is the name of a document in firebase database
    const videos = [];
    this.videoService.getData(section).subscribe((section) => {
      if (section.videos.length !== 0) {
        const video = section.videos.filter((video) => {
          return video.url === `https://vimeo.com/${id}`;
        })[0];
        if (video === undefined) {
          this.router.navigateByUrl("404notfound");
        } else {
          const videoName = video.name;
          const videoUrl = this.videoService.convertToBackgroundPlayerLink(
            video.url
          );
          console.log(`Video url: ${video.url}`);
          const popupVideoUrl = this.videoService.convertToPlayerLink(
            video.url
          );
          this.videoName = videoName;
          this.backgroundVideoSrc = videoUrl;
          this.popupSrc = popupVideoUrl;
        }
      }
    });
  }

  private oniframeLoad(event) {
    const elementSrc = event.target.src;
    if (elementSrc !== "") {
      const player = new Player(event.target);
      this.backgroundPlayer = player;
      player.on("play", () => {
        this.loaded = true;
      });
    }
  }

  private onPopupLoad(event) {
    const elementSrc = event.target.src;
    if (elementSrc !== "") {
      const player = new Player(event.target);
      this.popupPlayer = player;
    }
  }
  private showShowreel() {
    if (!this.popup.nativeElement.classList.contains("showpopup")) {
      this.popup.nativeElement.classList.add("showpopup");
      this.backgroundPlayer.pause();
    }
  }

  private hideShowreel() {
    if (this.popup.nativeElement.classList.contains("showpopup")) {
      this.popup.nativeElement.classList.remove("showpopup");
      this.popupPlayer.pause();
      this.backgroundPlayer.play();
    }
  }

  onLearnMoreClick($element) {
    $element.scrollIntoView();
  }
}
