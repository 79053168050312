import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  user;
  isAuthenticated;
  loggedIn;
  constructor(public auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.auth.getUserObservable().subscribe((user) => {
      this.user = user;
    });
    this.auth.userIsAuthenticated().subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.auth.isLoggedIn().subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
  }

  navigateToAdmin() {
    this.router.navigateByUrl("/admin");
  }
}
