import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AngularFireModule } from "@angular/fire";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavComponent } from "./components/nav/nav.component";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AuthService } from "./services/auth.service";
import { VideoService } from "./services/video.service";
import { NewvideoComponent } from "./components/newvideo/newvideo.component";
import { LoginComponent } from "./components/login/login.component";
import { SafePipe } from "./safe-pipe.pipe";
import { LoadingComponent } from "./components/loading/loading.component";
import { VideosPageComponent } from "./components/videos/videos-page/videos-page.component";
import { VideoPageComponent } from "./components/videos/video-page/video-page.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AuthGaurd } from "./gaurds/auth.gaurd";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { AdminPageComponent } from "./components/admin-page/admin-page.component";
import { EditVideoComponent } from "./components/edit-video/edit-video.component";
import { ShowQuoteandBrands } from "./services/showquoteandbrands.service";
import { FunctiontestService } from './services/functiontest.service';
import { PasswordComponent } from './components/password/password.component';
import { DevLockService } from './services/dev-lock.service';
import { DevGuardGuard } from './gaurds/dev-guard.guard';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LandingPageComponent,
    FooterComponent,
    NewvideoComponent,
    LoginComponent,
    SafePipe,
    LoadingComponent,
    VideosPageComponent,
    VideoPageComponent,
    NotFoundComponent,
    AboutUsComponent,
    ContactUsComponent,
    AdminPageComponent,
    EditVideoComponent,
    PasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    AngularFireFunctionsModule,
  ],
  providers: [
    AuthService,
    VideoService,
    FunctiontestService,
    DevLockService,
    AuthGaurd,
    DevGuardGuard,
    { provide: Window, useValue: window },
    ShowQuoteandBrands,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
