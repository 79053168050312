import { NgModule } from "@angular/core";
import { AuthGaurd } from "./gaurds/auth.gaurd";
import { DevGuardGuard } from './gaurds/dev-guard.guard';
import { Routes, RouterModule } from "@angular/router";
import { LandingPageComponent } from "./components/landing-page/landing-page.component";
import { NewvideoComponent } from "./components/newvideo/newvideo.component";
import { LoginComponent } from "./components/login/login.component";
import { VideosPageComponent } from "./components/videos/videos-page/videos-page.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { VideoPageComponent } from "./components/videos/video-page/video-page.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { ContactUsComponent } from "./components/contact-us/contact-us.component";
import { AdminPageComponent } from "./components/admin-page/admin-page.component";
import { EditVideoComponent } from "./components/edit-video/edit-video.component";
import { PasswordComponent } from './components/password/password.component';

const routes: Routes = [
  { path: "", component: LandingPageComponent, canActivate: [DevGuardGuard] },
  { path: "404notfound", component: NotFoundComponent, canActivate: [DevGuardGuard] },
  { path: "admin", component: AdminPageComponent, canActivate: [AuthGaurd, DevGuardGuard] },
  {
    path: "admin/edit-video/:section/:id",
    component: EditVideoComponent,
    canActivate: [AuthGaurd, DevGuardGuard],
  },
  {
    path: "admin/newvideo",
    component: NewvideoComponent,
    canActivate: [AuthGaurd, DevGuardGuard],
  },
  { path: "login", component: LoginComponent, canActivate: [DevGuardGuard] },
  { path: "about", component: AboutUsComponent, canActivate: [DevGuardGuard] },
  { path: "contact", component: ContactUsComponent, canActivate: [DevGuardGuard] },
  { path: "videos/:section", component: VideosPageComponent, canActivate: [DevGuardGuard] },
  { path: "videos/:section/:id", component: VideoPageComponent, canActivate: [DevGuardGuard] },
  { path: "password", component: PasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
