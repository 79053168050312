import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class VideoService {
  private section: AngularFirestoreDocument<{ videos: String[] }>;
  sectionObservable: Observable<{ videos: String[] }>;

  constructor(public db: AngularFirestore, private http: HttpClient) {}

  // getVideos() {
  //   this.http.get("/api/videos/digital").subscribe((videos: any[]) => {
  //     const newData = videos.map((video) => {
  //       return {
  //         name: video.name,
  //         url: video.url,
  //         id: this.getVideoId(video.url),
  //         image: video.image,
  //       };
  //     });
  //     console.log(newData);
  //     this.db.doc(`sections/productshoot`).update({ videos: newData });
  //   });
  // }

  updateVideo(sectionName, newVideo) {
    console.log("update video called");
    this.getData(sectionName)
      .pipe(take(1))
      .subscribe((section) => {
        let newData = section;
        let id = newVideo.id;
        section.videos.forEach((video, index) => {
          if (video.id === id) {
            newData.videos[index] = newVideo;
          }
          if (index === section.videos.length - 1) {
            console.log("updating video");
            this.db.doc(`sections/${sectionName}`).update(newData);
          }
        });
      });
  }

  addVideo(sectionName, newVideo) {
    console.log("add video called");
    this.getData(sectionName)
      .pipe(take(1))
      .subscribe((section) => {
        let newData = section;
        newData.videos.push(newVideo);
        this.db.doc(`sections/${sectionName}`).update(newData);
      });
  }

  deleteVideo(sectionName, videoToBeDeleted) {
    console.log("delete video called");
    this.getData(sectionName)
      .pipe(take(1))
      .subscribe((section) => {
        let newData = { ...section };
        let id = videoToBeDeleted.id;
        newData.videos = section.videos.filter((video) => video.id !== id);
        console.log(newData);
        this.db.doc(`sections/${sectionName}`).update(newData);
      });
  }

  refreshThumbnail(sectionName, id) {
    console.log("in refresh thumbnail video service");
    console.log("id: " + id);
    this.getVimeoVideoData(id).subscribe((data: any) => {
      console.log("got image data");
      let image = data.pictures.sizes.filter((size) => size.width === 295)[0];
      this.getData(sectionName).subscribe((section) => {
        let newData = section;
        section.videos.forEach((video, index) => {
          if (video.id === id) {
            const newVideo = { ...video };
            newVideo.image = image.link;
            newData.videos[index] = newVideo;
            console.log("updated newData with newVideo");
          }

          if (index === section.videos.length - 1) {
            this.db.doc(`sections/${sectionName}`).update(newData);
          }
        });
      });
    });
  }

  getVimeoVideoData(id) {
    let header = {
      headers: new HttpHeaders().set(
        "Authorization",
        "Bearer e8ae0f538f51b2d469b00f1ad479f069"
      ),
    };
    return this.http.get(`https://api.vimeo.com/videos/${id}`, header);
  }

  getData(section): Observable<{ videos: any[] }> {
    this.section = this.db.doc<{ videos: any[] }>(`sections/${section}`);
    this.sectionObservable = this.section.valueChanges();

    return this.sectionObservable;
  }

  // converting link like this:
  // https://vimeo.com/{video-id}
  // to link like this:
  // https://player.vimoe.com/videos/{video-id}?queries...
  convertToBackgroundPlayerLink(originalLink) {
    let vidId = this.getVideoId(originalLink);
    const finalLink = `https://player.vimeo.com/video/${vidId}?background=1&loop=1&byline=0&title=0`;

    return finalLink;
  }

  convertToPlayerLink(originalLink) {
    const vidId = this.getVideoId(originalLink);
    const finalLink = `https://player.vimeo.com/video/${vidId}`;

    return finalLink;
  }

  getVideoId(videoLink) {
    const splitSrc = videoLink.replace(/^https?\:\/\//i, "").split("/");
    const videoId = parseInt(splitSrc[1]);

    return videoId;
  }

  // This was just a temporary function to add id fields. You can use ot for the same if you want later:
  // setData() {
  //   const sectionName = "digital";
  //   this.db
  //     .doc(`sections/${sectionName}`)
  //     .valueChanges()
  //     .subscribe((data: any) => {
  //       let newData: any = data;
  //       data.videos.forEach((video, i) => {
  //         let id = this.getVideoId(video.url);
  //         newData.videos[i].id = id;
  //         if (i === data.videos.length - 1) {
  //           console.log("End");
  //           console.log(newData);
  //           this.db.doc(`sections/${sectionName}`).update(newData);
  //         }
  //       });
  //     });
  // }
}
