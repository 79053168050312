import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FunctiontestService {

  constructor(private http: HttpClient) { }

  hello() {
    this.http.get('https://us-central1-ddbwebsite-f89ac.cloudfunctions.net/helloWorld').subscribe(data => {
      console.log(data);
    });
    // console.log(res);
  }
}
